import React from "react"
import Layout from "../../src/components/layout"
import GreenLine from "../components/layout/greenLine"
import { Helmet } from "react-helmet"

const DisclaimerPage = () => {
  return (
    <Layout type="hr" noHeaderOptions>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Disclaimer</title>
      </Helmet>
      <div className="flex justify-center flex-1 h-full w-full max-w-4xl w-4/5 md:h-auto md:pt-8 md:pb-20">
        <div className={`w-full shadow bg-white  `}>
          <GreenLine />
          <div className="px-5">
            <h1 className="text-4xl my-4">Disclaimer</h1>

            <p className="text-sm font-sans italic">Last updated: January 16, 2020</p>

            <h1 className="text-xl my-4">Interpretation and Definitions</h1>
            <h2 className="text-lg my-4">Interpretation</h2>
            <p className="text-sm font-sans">
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
            </p>
            <p className="text-sm font-sans">
              The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>

            <h2 className="text-lg my-4">Definitions</h2>
            <p className="text-sm font-sans">For the purposes of this Disclaimer:</p>
            <ul>
              <li>
                <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to atolo, Property
                Of Atolo SA/NV Avenue de Tervurenlaan 36/18, 1040 Brussels.
              </li>
              <li>
                <strong>You</strong> means the individual accessing the Service, or the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
              <li>
                <strong>Website</strong> refers to atolo.netlify.com (atolo).
              </li>{" "}
              <li>
                <strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device named
                atolo.
              </li>{" "}
              <li>
                <strong>Service</strong> refers to the Website or the Application or both.
              </li>
            </ul>

            <h1 className="text-xl my-4">Disclaimer</h1>
            <p className="text-sm font-sans">The information contained on the Service is for general information purposes only.</p>
            <p className="text-sm font-sans">The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
            <p className="text-sm font-sans">
              In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages
              whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or
              the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the
              Service at any time without prior notice. This Disclaimer has been created with the help of{" "}
              <a href="https://www.termsfeed.com/disclaimer-generator/">Disclaimer Generator</a>
            </p>
            <p className="text-sm font-sans">The Company does not warrant that the Service is free of viruses or other harmful components.</p>

            <h1 className="text-xl my-4">External Links Disclaimer</h1>
            <p className="text-sm font-sans">
              The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.
            </p>
            <p className="text-sm font-sans">
              Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these
              external websites.
            </p>

            <h1 className="text-xl my-4">Errors and Omissions Disclaimer</h1>
            <p className="text-sm font-sans">
              The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to
              insure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules
              and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.
            </p>
            <p className="text-sm font-sans">
              The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.
            </p>

            <h1 className="text-xl my-4">Fair Use Disclaimer</h1>
            <p className="text-sm font-sans">
              The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making
              such material available for criticism, comment, news reporting, teaching, scholarship, or research.
            </p>
            <p className="text-sm font-sans">
              The Company believes this constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the United States
              Copyright law.
            </p>
            <p className="text-sm font-sans">
              If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from
              the copyright owner.
            </p>

            <h1 className="text-xl my-4">Views Expressed Disclaimer</h1>
            <p className="text-sm font-sans">
              The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position
              of any other author, agency, organization, employer or company, including the Company.
            </p>
            <p className="text-sm font-sans">
              Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel
              or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable
              for any comment published by users and reserve the right to delete any comment for any reason whatsoever.
            </p>

            <h1 className="text-xl my-4">No Responsibility Disclaimer</h1>
            <p className="text-sm font-sans">
              The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting,
              tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional
              accounting, tax, legal or other competent advisers.
            </p>
            <p className="text-sm font-sans">
              In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever
              arising out of or in connection with your access or use or inability to access or use the Service.
            </p>

            <h1 className="text-xl my-4">"Use at Your Own Risk" Disclaimer</h1>
            <p className="text-sm font-sans">
              All information in the Service is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained
              from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of
              performance, merchantability and fitness for a particular purpose.
            </p>
            <p className="text-sm font-sans">
              The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the
              Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.
            </p>

            <h2 className="text-lg my-4">Contact Us: Atolo</h2>
            <p className="text-sm font-sans">If you have any questions about this Disclaimer, You can contact Us:</p>
            <div className="flex mb-3 mt-2 text-sm">
              <div className="opacity-100 hidden md:block">
                Property Of Atolo SA/NV
                <br /> Avenue de Tervurenlaan 36/18, <br />
                1040 Brussels
                <br /> Tel: +32 2 609 50 90
              </div>
              <div className="opacity-100 ml-3 hidden md:block">
                Atolo Sàrl
                <br />
                Rue du Petit-Chêne 18ter, <br />
                1003 Lausanne
                <br />
                Tel: +41 21 806 55 55 | +41 78 736 92 58
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DisclaimerPage
